.c-base-hotspot {
  display: block;
  position: relative;

  > img {
    display: block;
  }

  .c-base-hotspot__item {
    position: absolute;
  }

  // hide original item when it is being dragged
  .draggable-source--is-dragging {
    opacity: 0;
  }

  // styles for button on admin screen only
  // user-facing button has access to bolt styles
  &.c-base-hotspot--editable {
    .c-base-hotspot__trigger {
      position: relative;
      width: 39.8333px;
      height: 39.8333px;
      padding: 0;
      font-size: 0.9rem;
      font-weight: 400;
      color: rgb(255, 255, 255);
      line-height: 1.45;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      border: 0;
      border-radius: 100%;
      background-color: rgb(62, 103, 187);
      transition: transform ease-in-out 200ms;

      &:hover {
        transform: scale(1.1);
      }

      &:after {
        content: '+';
        position: absolute;
        transform: translate3d(-50%, -50%, 0);
        font-size: 2.5em;
      }

      &:before {
        content: '';
        opacity: 0.6;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        border-radius: inherit;
        box-shadow: 0 0.2em 0.75em #000;
      }
    }
  }
}
